class QSocket {
  constructor(host, onsuccess, onerror, protocol = null) {
    this.channels = {};
    this.onerror = onerror;
    this.onsuccess = onsuccess;
    this.closing = false;
    this.reconnectTimeout = 1;
    this.ws = null;
    this.connected = false;
    this.host = host;
    if (protocol == null) {
      protocol = 'wss://';
      if (window.location.protocol == 'http:') {
        protocol = 'ws://';
      }
    }
    this.protocol = protocol;
    this.open();
  }
  open() {
    let pingInterval = null;
    this.ws = null;
    var ws = new WebSocket(this.protocol + this.host);
    ws.onopen = (evt) => {
      if (!pingInterval) {
        pingInterval = setInterval(() => {
          if (ws.readyState != ws.OPEN) {
            clearInterval(pingInterval);
          }
          ws.send('');
        }, 10000);
      }
      this.connected = true;
      if (this.onsuccess) {
        this.onsuccess(evt);
      }
    };
    ws.onclose = () => {
      this.connected = false;
      if (pingInterval) {
        clearInterval(pingInterval);
      }
      if (this.closing) {
        return;
      }
      this.closing = false;
      setTimeout(() => {
        this.open();
        if (this.reconnectTimeout < 8192) {
          this.reconnectTimeout *= 2;
        }
      }, this.reconnectTimeout);
    };
    ws.onmessage = (evt) => {
      var response = JSON.parse(evt.data);
      if (response && response.channel && response.event && this.channels[response.channel] && this.channels[response.channel][response.event]) {
        this.channels[response.channel][response.event](response.content);
      }
    };
    ws.onerror = (evt) => {
      if (this.onerror) {
        this.onerror(evt);
      }
      console.log('Socket error', evt);
    };
    this.ws = ws;
  }
  subscribe(channel, event, callback) {
    if (!this.channels[channel]) {
      this.channels[channel] = {};
    }
    this.channels[channel][event] = callback;
  }
  unsubscribe(channel) {
    this.channels[channel] = {};
  }
  send(data) {
    this.ws.send(JSON.stringify(data));
  }
  close() {
    this.closing = true;
    this.ws.close();
  }
}

export default class Notification {
  constructor(onsuccess = null, onerror = null) {
    this.qsocket = null;
    this.onsuccess = onsuccess;
    this.onerror = onerror;
    this.Local = {};
  }

  subscribe(channel, event, callback) {
    if (!this.qsocket) {
      this.qsocket = new QSocket(window.location.host + '/api/manage/ws', this.onsuccess, this.onerror);
    }
    if (this.qsocket) {
      this.qsocket.subscribe(channel, event, (e) => {
        callback(e);
      });
    } else {
      console.log('Echo not defined');
    }
    if (!this.Local[channel]) {
      this.Local[channel] = {};
    }
    this.Local[channel][event] = callback;
  }

  unsubscrbe(channel) {
    if (this.qsocket) {
      this.qsocket.unsubscribe(channel);
    }
  }

  notify(channel, event, e = null) {
    if (!this.qsocket) {
      this.qsocket = new QSocket(window.location.host + '/api/manage/ws', this.onsuccess, this.onerror);
    }
    if (this.Local[channel] && this.Local[channel][event]) {
      this.Local[channel][event](e);
    }
  }
  close() {
    this.qsocket.close();
  }
}
