<template>
  <nav class="navigation">
    <router-link to="/" class="logo"><img src="~@/assets/icons/quadraturaLogo.svg" /> </router-link>

    <div class="navigation-desktop main-navigation">
      <router-link :to="{ name: 'Home' }" class="nav-item" exact="" active-class="activePage">
        <div class="navigation-image-box forms" :class="{ activeRoute: $route.name === 'Home' }"></div>
        <p>FORMS</p>
      </router-link>

      <router-link :to="{ name: 'Devices' }" class="nav-item" active-class="activePage">
        <div class="navigation-image-box devices" :class="{ activeRoute: $route.name === 'Devices' }"></div>
        <p>DEVICES</p>
      </router-link>

      <router-link
        :to="{ name: 'Statistics' }"
        class="nav-item"
        active-class="activePage"
        :class="{
          activePage: $route.name === 'Statistics' || $route.name === 'StatisticsQr',
        }"
      >
        <div class="navigation-image-box statistics" :class="{ activeRoute: $route.name === 'Statistics' || $route.name === 'StatisticsQr' }"></div>
        <p>STATISTICS</p>
      </router-link>
      <router-link class="nav-item" active-class="activePage" :to="{ name: 'Instructions' }">
        <div class="navigation-image-box instructions" :class="{ activeRoute: $route.name === 'Instructions' }"></div>
        <p>Instructions</p>
      </router-link>
    </div>
    <div class="sign-out-navigation navigation-desktop">
      <p class="userName">{{ profile.name }}</p>
      <div class="linkIcons" @click="desktopNav = !desktopNav">
        <img src="@/assets/icons/hamburgerCloseIcon.svg" v-if="desktopNav" />
        <img src="@/assets/icons/hamburgerIcon.svg" v-else />
      </div>
    </div>

    <div class="navigation-hamburger">
      <button @click="closeNavigationMobile" v-if="navMobile">
        <img src="@/assets/icons/hamburgerCloseIcon.svg" />
      </button>
      <button @click="openNavigationMobile" v-else>
        <img src="@/assets/icons/hamburgerIcon.svg" />
      </button>
    </div>
    <div class="navigation-options" v-if="navMobile">
      <div class="nav-item-wrapper">
        <div class="nav-item user-name">
          <p>{{ profile.name }}</p>
        </div>
      </div>
      <div class="nav-item-wrapper">
        <CustomDropdown title="Country" :options="groups" :default="group" v-model="group" />
      </div>
      <router-link :to="{ name: 'Home' }" class="nav-item-wrapper" exact="">
        <div :to="{ name: 'Home' }" class="nav-item">
          <div class="navigation-image-box forms"></div>
          <p>FORMS</p>
        </div>
      </router-link>
      <router-link :to="{ name: 'Devices' }" class="nav-item-wrapper">
        <div class="nav-item">
          <div class="navigation-image-box devices"></div>
          <p>DEVICES</p>
        </div>
      </router-link>
      <router-link :to="{ name: 'Statistics' }" class="nav-item-wrapper">
        <div class="nav-item">
          <div class="navigation-image-box statistics"></div>
          <p>STATISTICS</p>
        </div>
      </router-link>
      <router-link :to="{ name: 'Instructions' }" class="nav-item-wrapper">
        <div class="nav-item">
          <div class="navigation-image-box instructions"></div>
          <p>Instructions</p>
        </div>
      </router-link>

      <div class="nav-item-wrapper" @click="logout">
        <div class="nav-item">
          <img src="~@/assets/icons/logoutIcon.svg" alt="Sign Out icon" />
          <p>Sign out</p>
        </div>
      </div>
    </div>
    <div class="desktop-navigation-additional" v-if="desktopNav">
      <div
        class="desktop-navigation-additional-box"
        v-click-outside="
          () => {
            desktopNav = false;
          }
        "
      >
        <div class="desktop-navigation-additional-box-option dropdown-box-option">
          <CustomDropdown value-key="uuid" title="Country" :options="groups" :default="group" v-model="group" />
        </div>
        <div class="desktop-navigation-additional-box-option" @click="logout">
          <img src="@/assets/icons/logoutIcon.svg" alt="Sign Out icon" />
          <p class="desktop-navigation-additional-box-option-text">Sign out</p>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      navMobile: false,
      desktopNav: false,
      group: '',
      groups: [],
      profile: {
        name: '',
      },
    };
  },
  methods: {
    openNavigationMobile() {
      this.navMobile = true;
      document.querySelector('body').style.overflow = 'hidden';
    },
    closeNavigationMobile() {
      this.navMobile = false;
      document.querySelector('body').style.overflow = 'auto';
    },
    logout() {
      window.location.href = '/logout';
    },
  },
  mounted() {
    this.$userData().then((res) => {
      this.profile = res;
      this.group = this.profile.group_uuid;
    });
    axios.get('/api/manage/groups').then((response) => {
      this.groups = response.data;
    });
  },
  watch: {
    group(val) {
      if (val && this.profile.group_uuid != val) {
        axios.post('/api/manage/group', { uuid: val }).then(() => {
          window.location.reload();
        });
      }
    },
  },
};
</script>
