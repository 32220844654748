<template>
  <div
    class="textarea-row"
    :class="{
      'resize-textarea': resizeTextArea,
    }"
  >
    <textarea placeholder=" " :id="'textarea' + id" v-model="val" @input="resize()" ref="textAreaBox" />
    <label :for="'textarea' + id">{{ placeHolder }} {{ errorMessage }}</label>
  </div>
</template>

<script>
export default {
  name: 'TextAreaComponent',
  props: {
    placeHolder: {
      type: String,
      default: 'placeHolder',
    },
    errorMessage: {},
    modelValue: {},
    resizeTextArea: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  emits: ['update:modelValue'],
  mounted() {
    this.id = Math.random().toString(16).slice(2);
    this.resize();
  },
  computed: {
    val: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    resize() {
      if (this.resizeTextArea) {
        let element = this.$refs.textAreaBox;
        if (element) {
          element.style.height = '39px';
          element.style.height = element.scrollHeight + 2 + 'px';
          if (element.scrollHeight > element.offsetHeight) {
            element.style.height = element.scrollHeight + 2 + 'px';
          }
          if (this.modelValue === '') {
            element.style.height = '39px';
          }
        }
      }
    },
  },
};
</script>
