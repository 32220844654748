<template>
  <div class="checkbox-component">
    <input type="checkbox" v-model="val" :value="valueKey" :id="'checkbox-switch_' + id" />
    <label :for="'checkbox-switch_' + id">
      {{ placeHolder }} <template v-if="errorMessage">- {{ errorMessage }}</template>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckBoxComponent',
  props: {
    valueKey: {
      type: String,
      default: '1',
    },
    placeHolder: {
      type: String,
      default: 'Checkbox',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      id: Math.random().toString(36).slice(2),
    };
  },
  computed: {
    val: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>
