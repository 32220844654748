<template>
  <div
    class="input-row"
    :class="{
      'input-row-read-only': readOnly,
    }"
  >
    <input :type="inputType" placeholder=" " :id="'input' + id" v-model="val" ref="inputComponent" :readonly="readOnly" />
    <label :for="'input' + id">{{ placeHolder }} {{ errorMessage }}</label>
  </div>
</template>

<script>
export default {
  name: 'InputComponent',
  props: {
    inputType: {
      type: String,
      default: 'text',
    },
    placeHolder: {
      type: String,
      default: 'placeHolder',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    modelValue: {},
  },
  data() {
    return {
      id: null,
    };
  },
  emits: ['update:modelValue'],
  mounted() {
    this.id = Math.random().toString(16).slice(2);
  },
  computed: {
    val: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>
