import { createRouter, createWebHistory } from 'vue-router';
// import NotFound from '@/views/NotFound';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Home.vue'),
  },
  // {
  //   path: '/models',
  //   name: 'Models',
  //   component: () => import(/* webpackChunkName: "auth" */ '../views/Models.vue'),
  // },
  {
    path: '/forms/create',
    name: 'Form',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Form.vue'),
  },
  {
    path: '/forms/:uuid/edit',
    name: 'FormEdit',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Form.vue'),
  },

  {
    path: '/instructions',
    name: 'Instructions',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Instructions.vue'),
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Devices.vue'),
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Statistics.vue'),
  },
  {
    path: '/statistics/qr',
    name: 'StatisticsQr',
    component: () => import(/* webpackChunkName: "auth" */ '../views/StatisticsQr.vue'),
  },
  // {
  //   path: '/:catchAll(.*)',
  //   name: 'NotFound',
  //   component: NotFound,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  },
});
router.beforeEach((_to, _form, next) => {
  document.body.style.overflow = 'auto';
  next();
});

export default router;
