<template>
  <div class="checkbox-slider">
    <label :for="'switch_' + id" class="checkbox-slider-label left-label" v-if="multiLabel" :class="{ 'active-double-label': multiLabel && !val, 'double-label': multiLabel }">{{ leftPlaceHolder }} </label>
    <label class="checkbox-switch" :for="'switch_' + id">
      <input type="checkbox" v-model="val" :value="valueKey" :id="'switch_' + id" />
      <span class="slider"></span>
    </label>
    <label :for="'switch_' + id" class="checkbox-slider-label" :class="{ 'active-double-label': multiLabel && val, 'double-label': multiLabel }">
      {{ placeHolder }} <template v-if="errorMessage">- {{ errorMessage }}</template></label
    >
  </div>
</template>

<script>
export default {
  name: 'CheckboxSlider',
  props: {
    valueKey: {
      type: String,
      default: '1',
    },
    placeHolder: {
      type: String,
      default: 'Checkbox',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    multiLabel: {
      type: Boolean,
      default: false,
    },
    leftPlaceHolder: {
      type: String,
      default: 'Left',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      id: Math.random().toString(36).slice(2),
    };
  },
  computed: {
    val: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>
