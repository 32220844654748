import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import Directives from './directives';
import Format from './mixins/Format';

import InputComponent from '@/components/InputComponent';
import Navigation from '@/components/Navigation';
import CustomDropdown from '@/components/CustomDropdown';
import CheckBoxComponent from './components/CheckBoxComponent.vue';
import CheckboxSliderComponent from './components/CheckboxSliderComponent.vue';
import TextAreaComponent from './components/TextAreaComponent.vue';
import NotificationQSocket from './services/NotificationQSocket.js';

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

let userData = null;

let loc = window.location.protocol + '//' + window.location.hostname.replace('dashboard.', '');

if (loc.indexOf('localhost') > -1) {
  loc = 'http://localhost:8081';
}
window.publicURL = loc;

axios
  .get('/api/manage/profile')
  .then((response) => {
    userData = response.data;
  })
  .finally(() => {
    const app = createApp(App);
    app.config.globalProperties.$http = axios;
    app.config.globalProperties.$notification = new NotificationQSocket(() => {
      app.config.globalProperties.$notification.notify('local', 'connected');
    });
    app.config.globalProperties.$userData = () => {
      if (userData === null) {
        return axios.get('/api/manage/profile').then((response) => {
          return (userData = response.data);
        });
      } else {
        return new Promise((resolve) => {
          resolve(userData);
        });
      }
    };
    Directives(app);
    app.component('InputComponent', InputComponent);
    app.component('Navigation', Navigation);
    app.component('CustomDropdown', CustomDropdown);
    app.component('CheckBoxComponent', CheckBoxComponent);
    app.component('CheckboxSliderComponent', CheckboxSliderComponent);
    app.component('TextAreaComponent', TextAreaComponent);
    app.mixin(Format);
    app.use(router).mount('#app');
  });
